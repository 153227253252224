.container {
  max-width: 480px;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #f4f4f4;
  padding-top: 4.25rem;
}

.error {
  padding: 1rem;
  margin-top: 0;
}

@media (min-width: 481px) {
  .container {
    border-left: 2px solid #f4f4f4;
    border-right: 2px solid #f4f4f4;
  }
}
